@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    user-select: none;
    -webkit-user-drag: none;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0 !important;
    padding: 0;
    font-family: 'Montserrat', sans-serif; /* Ensure intentional */
 
}

/* Modal Styles */
.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex; /* Center the modal content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 9999; /* Ensure the modal is on top */
}



.bg-black {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-white {
    background-color: white;
    position: relative; /* For proper stacking context */
    z-index: 10000; /* Ensure this is above other elements */
    max-width: 400px; /* Set max width for the modal */
    width: 100%; /* Full width up to max-width */
}

.rounded-lg {
    border-radius: 0.5rem;
}

.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-5 {
    padding: 1.25rem;
}

.w-1\/3 {
    width: 33.3333%;
}


/* Scrollbar Styles */
::-webkit-scrollbar {
    width: 4px;
    height: 80px;
}

::-webkit-scrollbar-track {
    background: #222A35;
}

::-webkit-scrollbar-thumb {
    background: #10295a;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: #626970;
}
